import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import SideBar from "../../components/SideBar/SideBar";
import TopNavbar from "../../components/SideBar/TopNavbar";
import Dashboard from "../../pages/AdminDashboard/Dashboard";
import Home from "../../pages/AdminDashboard/Home/Home";
import TeacherDash from "../../pages/AdminDashboard/Ad-TeacherDashboard/TeacherDash";
import Notification from "../../pages/AdminDashboard/Notification/Notification";
import AllNotification from "../../pages/AdminDashboard/Notification/AllNotification";
import NewNotification from "../../pages/AdminDashboard/Notification/NewNotification";
import UnreadNotification from "../../pages/AdminDashboard/Notification/UnreadNotification";
import OnlineTutoring from "../../pages/AdminDashboard/Ad-TeacherDashboard/OnlineTutoring";
import HomeTutoring from "../../pages/AdminDashboard/Ad-TeacherDashboard/HomeTutoring";
import TeacherMessage from "../../pages/AdminDashboard/Ad-TeacherDashboard/TeacherMessage";
import TeacherReport from "../../pages/AdminDashboard/Ad-TeacherDashboard/TeacherReport";
import Teacher from "../../pages/AdminDashboard/Ad-TeacherDashboard/Teacher";
import TeacherProfile from "../../pages/AdminDashboard/Ad-TeacherDashboard/TeacherProfile";
import TeacherPayment from "../../pages/AdminDashboard/Ad-TeacherDashboard/TeacherPayment";
import HomeTeacher from "../../pages/AdminDashboard/HomeTeacher/HomeTeacher";
import Report from "../../pages/AdminDashboard/Report/Report";
import TeacherNotification from "../../pages/AdminDashboard/Ad-TeacherDashboard/TeacherNotification";
import TeacherNewNotification from "../../pages/AdminDashboard/Ad-TeacherDashboard/TeacherNewNoti";
import TeacherUnreadNotification from "../../pages/AdminDashboard/Ad-TeacherDashboard/TeacherUnreadNoti";
import Error404 from "../../pages/AdminDashboard/Error404";
import OnlineClasses from "../../pages/AdminDashboard/OnlineTutoring/OnlineClasses";
import TeacherChat from "../../pages/AdminDashboard/Ad-TeacherDashboard/TeacherChat";
import TeacherStartClass from "../../pages/AdminDashboard/Ad-TeacherDashboard/TeacherStartClass";
import Student from "../../pages/AdminDashboard/Ad-StudentDashboard/Student";
import StudentDash from "../../pages/AdminDashboard/Ad-StudentDashboard/StudentDash";
import StudentTution from "../../pages/AdminDashboard/Ad-StudentDashboard/StudentTution";
import FindTeacher from "../../pages/AdminDashboard/Ad-StudentDashboard/FindTeacher";
import HomeTeacherList from "../../pages/AdminDashboard/Ad-StudentDashboard/HomeTeacherList";
import OnlineTeacherList from "../../pages/AdminDashboard/Ad-StudentDashboard/OnlineTeacherList";
import HomeTution from "../../pages/AdminDashboard/Ad-StudentDashboard/HomeTution";
import TeacherPortfolio from "../../pages/AdminDashboard/Ad-StudentDashboard/TeacherPortfolio";
import StuProfile from "../../pages/AdminDashboard/Ad-StudentDashboard/StuProfile";
import StudentPayment from "../../pages/AdminDashboard/Ad-StudentDashboard/StudentPayment";
import PaymentDetail from "../../pages/AdminDashboard/Ad-StudentDashboard/PaymentDetail";
import StudentNotification from "../../pages/AdminDashboard/Ad-StudentDashboard/StudentNotification";
import StudentNewNotification from "../../pages/AdminDashboard/Ad-StudentDashboard/StudentNewNoti";
import StudentUnreadNotification from "../../pages/AdminDashboard/Ad-StudentDashboard/StudentUnreadNoti";
import TeacherPortfolio2 from "../../pages/AdminDashboard/Ad-StudentDashboard/TeacherPortfolio2";
import HomeTeacherPortfolio from "../../pages/AdminDashboard/Ad-StudentDashboard/HomeTeacherPortfolio";
import HomeTeacherPortfolio2 from "../../pages/AdminDashboard/Ad-StudentDashboard/HomeTeacherPortfolio2";
import MyAccount from "../../pages/AdminDashboard/Setting/MyAccount";
import WebsiteSetting from "../../pages/AdminDashboard/Setting/WebsiteSetting";
import NotificationSetting from "../../pages/AdminDashboard/Setting/NotificationSetting";
import EmailTemplate from "../../pages/AdminDashboard/Setting/EmailTemplate";
import TeacherPaymentDetail from "../../pages/AdminDashboard/Ad-TeacherDashboard/TeacherPaymentDetail";
import AcademicClass from "../../pages/AdminDashboard/AcademicZone/AcademicClass";
import AcademicSubject from "../../pages/AdminDashboard/AcademicZone/AcademicSubject";
import AcademicSkill from "../../pages/AdminDashboard/AcademicZone/AcademicSkill";
import AcademicLanguage from "../../pages/AdminDashboard/AcademicZone/AcademicLanguage";
import DemoClass from "../../pages/AdminDashboard/AcademicZone/DemoClass";
import Upcoming from "../../pages/AdminDashboard/Meetings/Upcoming";
import Completed from "../../pages/AdminDashboard/Meetings/Completed";
// import StudentCard from "../../pages/AdminDashboard/Ad-StudentDashboard/StudentCard";
import StudentChat from "../../pages/AdminDashboard/Ad-StudentDashboard/StudentChat";
import AdminAddSubject from "../../pages/AdminDashboard/Ad-TeacherDashboard/AdminAddSubject";

function AdminLayout() {
  const { pathname } = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    if (window.innerWidth <= 1024) {
      setIsSidebarOpen(!isSidebarOpen);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1024) {
        setIsSidebarOpen(false);
      } else {
        setIsSidebarOpen(true);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    const user = JSON.parse(localStorage.getItem("edo-admin"));
    if (!user) {
      navigate("/");
    }
  }, [navigate, pathname]);

  return (
    <>
      <TopNavbar toggleSidebar={toggleSidebar} />
      <div className="flex">
        <SideBar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

        <div className="h-screen flex-1 px-2 mt-5">
          <Routes>
            {/* Notification----------------- */}
            <Route path="/notification" element={<Notification />} />
            <Route path="/allNotification" element={<AllNotification />} />
            <Route path="/newNotification" element={<NewNotification />} />
            <Route
              path="/unreadNotification"
              element={<UnreadNotification />}
            />

            {/* Dashboard--------------------- */}
            <Route path="" element={<Dashboard />} />

            {/* HOme----------------------------- */}
            <Route path="/home" element={<Home />} />

            {/* Online-Tutoring---------------- */}
            {/* <Route path="/onlineTutor" element={<OnlineTutor />} /> */}
            <Route path="/onlineClasses" element={<OnlineClasses />} />

            {/* Home-Tutoring------------------- */}
            <Route path="/homeTeacher" element={<HomeTeacher />} />

            {/* Teacher ------------------------ */}
            <Route path="/teacher" element={<Teacher />} />
            <Route path="/teacherDash" element={<TeacherDash />} />
            <Route path="/onlineTutoring" element={<OnlineTutoring />} />
            <Route path="/homeTutoring" element={<HomeTutoring />} />
            <Route path="/teacherMessage" element={<TeacherMessage />} />
            <Route path="/teacherReport" element={<TeacherReport />} />
            <Route path="/teacherProfile" element={<TeacherProfile />} />
            <Route path="/teacherPayment" element={<TeacherPayment />} />
            <Route
              path="/teacherPaymentDetail"
              element={<TeacherPaymentDetail />}
            />
            <Route
              path="/teacherNotification"
              element={<TeacherNotification />}
            />
            <Route
              path="/teacherNewNoti"
              element={<TeacherNewNotification />}
            />
            <Route
              path="/teacherUnreadNoti"
              element={<TeacherUnreadNotification />}
            />
            <Route path="/teacherChat" element={<TeacherChat />} />
            <Route path="/teacherStartClass" element={<TeacherStartClass />} />

            {/* Student------------------------------ */}
            <Route path="/student" element={<Student />} />
            <Route path="/studentDash" element={<StudentDash />} />
            <Route path="/studentTution" element={<StudentTution />} />
            <Route path="/findTeacher" element={<FindTeacher />} />
            <Route path="/homeTeacherList" element={<HomeTeacherList />} />
            <Route path="/homeTution" element={<HomeTution />} />
            <Route path="/onlineTeacherList" element={<OnlineTeacherList />} />
            <Route path="/studentChat" element={<StudentChat />} />
            <Route path="/editsubject" element={<AdminAddSubject />} />
            <Route
              path="/homeTeacherPortfolio"
              element={<HomeTeacherPortfolio />}
            />
            <Route
              path="/homeTeacherPortfolio2"
              element={<HomeTeacherPortfolio2 />}
            />
            <Route path="/teacherPortfolio" element={<TeacherPortfolio />} />
            <Route path="/teacherPortfolio2" element={<TeacherPortfolio2 />} />
            <Route path="/studentProfile" element={<StuProfile />} />
            <Route path="/studentPayment" element={<StudentPayment />} />
            <Route path="/paymentDetail" element={<PaymentDetail />} />
            <Route
              path="/studentNotification"
              element={<StudentNotification />}
            />
            <Route
              path="/studentNewNoti"
              element={<StudentNewNotification />}
            />
            <Route
              path="/studentUnreadNoti"
              element={<StudentUnreadNotification />}
            />

            {/* Meetings  */}

            <Route path="/upcoming" element={<Upcoming/>}/>
            <Route path="/completed" element={<Completed/>} />


            {/* Academic-Zone */}
            <Route path="/acClass" element={<AcademicClass />} />
            <Route path="/acSubject" element={<AcademicSubject />} />
            <Route path="/acSkill" element={<AcademicSkill />} />
            <Route path="/acLanguage" element={<AcademicLanguage />} />
            <Route path="/demoClass" element={<DemoClass />} />

            {/* Report--------------------------- */}
            <Route path="/report" element={<Report />} />

            {/* Setting--------------------------- */}
            <Route path="/myAccount" element={<MyAccount />} />
            <Route path="/webSetting" element={<WebsiteSetting />} />
            <Route path="/settingNoti" element={<NotificationSetting />} />
            <Route path="/emailTemplate" element={<EmailTemplate />} />

            {/* Error-404------------------------- */}
            <Route path="/error" element={<Error404 />} />
          </Routes>
        </div>
      </div>
    </>
  );
}

export default AdminLayout;
