import React, { useEffect, useState } from "react";
import { FaPlus, FaRegTrashAlt } from "react-icons/fa";
import { TbBallpen } from "react-icons/tb";
import "react-phone-input-2/lib/style.css";
import { NavLink } from "react-router-dom";
import apiKey from "../../../config/apiKey";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import { useMemo } from "react";
import { getHitID } from "../../../config/HitIDStorage";
const typeIdToOption = {
  1: "home",
  2: "online",
  3: "both",
};

const AdminAddSubject = () => {
    const teacher_id = getHitID();
    
  const [typeId, setTypeId] = useState(null);
  const admin = JSON.parse(localStorage.getItem("edo-admin"));
//   const Id = admin ? admin.admin.id : "";
  const [selectedOption, setSelectedOption] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    setLoading(true); // Start loading before data fetch
    axios
      .get(`${apiKey}/api/edolutions/teacher/classes`, {
        headers: {
          Authorization: `Bearer ${admin?.token}`,
        },
      })
      .then((res) => {
        setClasses(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
    axios
      .get(`${apiKey}/api/edolutions/admin/ad-subjects?teacher_id=${teacher_id}`, {
        headers: {
          Authorization: `Bearer ${admin?.token}`,
        },
      })
      .then((res) => {
        setMySubjects(res.data);

      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [teacher_id,admin?.token]);

  useEffect(() => {
    axios
      .get(`${apiKey}/api/edolutions/admin/ad-subjects/user/${teacher_id}`, {
        headers: {
          Authorization: `Bearer ${admin?.token}`,
        },
      })
      .then((res) => {
        const fetchedTypeId = res.data.type_id;
        setTypeId(fetchedTypeId);
        // console.log("type id of the user is :", fetchedTypeId);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [teacher_id, admin?.token]);

  useEffect(() => {
    if (typeId !== null) {
      setSelectedOption(typeIdToOption[typeId] || "");
    }
  }, [typeId]);

  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [mySubjects, setMySubjects] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [id, setId] = useState([]);
  const [editclass, setEditClass] = useState([]);
  const [editsubject, setEditSubject] = useState([]);
  const [edithome, setEditHome] = useState([]);
  const [editCharge, setEditCharge] = useState([]);
  const [classes, setClasses] = useState([]);
  const [edit, setEdit] = useState([]);
  const [formData, setFormData] = useState({
    teacher_id: teacher_id,
    class_id: "",
    subjects: [],
    type_id: typeId,
    online_charges: "",
  
  });
  // const resetFormData=()=>{

  //   }
  const initialFormData = {
    teacher_id: teacher_id,
    class_id: "",
    subjects: [],
    type_id: typeId, // This should come from props or state
    online_charges: "",
    home_charges: "",
  };

  useEffect(() => {
    if (typeId !== null) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        type_id: typeId, // Update formData when typeId changes
      }));
    }
  }, [typeId]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: "",
    });
  };

  const [isLoading, setIsLoading] = useState(false); // Add isLoading state

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormData((prevFormData) => ({
        ...prevFormData,
        'teacher_id': teacher_id // Update formData when typeId changes
      }));
    setIsLoading(true); // Set loading to true when the form is submitted

    axios
      .post(`${apiKey}/api/edolutions/admin/ad-subjects`, formData, {
        headers: {
          Authorization: `Bearer ${admin?.token}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            backgroundColor: "#4FD1C5", // Customize background color
            color: "#fff", // Customize text color
          },
        });

        // Reset the form fields
        setFormData({
          class_id: "",
          subjects: [],
          type_id: typeId,
          online_charges: "",
          home_charges: "",
        });

        setSelectedOptions([]); // Reset the selected options
        setShowModal(false); // Close the modal

        // Refresh subjects
        axios
          .get(`${apiKey}/api/edolutions/admin/ad-subjects?teacher_id=${teacher_id}`, {
            headers: {
              Authorization: `Bearer ${admin?.token}`,
            },
          })
          .then((res) => {
            setMySubjects(res.data);
            
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.data) {
          setErrors(err.response.data.data);
        } else {
          setErrors({ general: "An unexpected error occurred" });
        }
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false after the request completes
      });
  };

  const [isLoading2, setIsLoading2] = useState(false); // Add isLoading state

  const handleSubmitEdit = (e) => {
    setIsLoading2(true); // Set loading to true when the form is submitted
    axios
      .get(`${apiKey}/api/edolutions/teacher/classes`, {
        headers: {
          Authorization: `Bearer ${admin?.token}`,
        },
      })
      .then((res) => {
        setClasses(res.data);
      })
      .catch((err) => {
        console.error(err);
      });

    e.preventDefault();
    axios
      .put(`${apiKey}/api/edolutions/admin/ad-subjects/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${admin?.token}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            backgroundColor: "#4FD1C5", // Customize background color
            color: "#fff", // Customize text color
          },
        });
        setShowModalEdit(false);
        setSelectedOptions("");
        // window.location.reload();
        axios
          .get(`${apiKey}/api/edolutions/admin/ad-subjects?teacher_id=${teacher_id}`, {
            headers: {
              Authorization: `Bearer ${admin?.token}`,
            },
          })
          .then((res) => {
            setMySubjects(res.data);
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.data) {
          setErrors(err.response.data.data);
        } else {
          setErrors({ general: "An unexpected error occurred" });
        }
      })
      .finally(() => {
        setIsLoading2(false); // Set loading to false after the request completes
      });
  };

  const handleDeleteSubject = (id) => {
    axios
      .delete(`${apiKey}/api/edolutions/admin/ad-subjects/${id}`, {
        headers: {
          Authorization: `Bearer ${admin?.token}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            backgroundColor: "#4FD1C5", // Customize background color
            color: "#fff", // Customize text color
          },
        });
        axios
          .get(`${apiKey}/api/edolutions/admin/ad-subjects?teacher_id=${teacher_id}`, {
            headers: {
              Authorization: `Bearer ${admin?.token}`,
            },
          })
          .then((res) => {
            setMySubjects(res.data);
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const handleFunctionEdit = (
    id,
    subject_name,
    class_name,
    home_charges,
    online_charges
  ) => {
    setId(id);
    setEditClass(class_name);
    setEditSubject(subject_name);
    setEditHome(home_charges);
    setEditCharge(online_charges);
    setShowModalEdit(true);
  
    axios
      .get(`${apiKey}/api/edolutions/admin/ad-subjects/${id}`, {
        headers: {
          Authorization: `Bearer ${admin?.token}`,
        },
      })
      .then((res) => {
        const defaultValueSub = res.data.map((sub) => {
          return { value: sub.subject_id, label: sub.subject_name };
        });

        setSelectedOptions(defaultValueSub);

        setEdit(res.data);

        setEditCharge(res.data[0].online_charges);
        setEditHome(res.data[0].home_charges);

        setFormData({
          ...formData,
          teacher_id: teacher_id,
          class_id: res.data[0].class_id,
          subject_id: res.data.subject_id,
          type_id: res.data[0].type_id,
          online_charges: res.data[0].online_charges,
          home_charges: res.data[0].home_charges,
          subjects: defaultValueSub.map((value) => value.value),
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const groupedSubjects = useMemo(() => {
    const groups = {};

    mySubjects.forEach((subject) => {
      if (!groups[subject.id]) {
        groups[subject.id] = [];
      }
      groups[subject.id].push(subject);
    });
    return groups;
  }, [mySubjects]);

  useEffect(() => {
    // console.log(formData.class_id);
    axios
      .get(`${apiKey}/api/edolutions/teacher/classes/${formData.class_id}`, {
        headers: {
          Authorization: `Bearer ${admin?.token}`,
        },
      })
      .then((res) => {
     
        setSubjects(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [formData.class_id, admin?.token]);

  const options = subjects.map((item) => ({
    value: item.subject_id,
    label: item.subject_name,
  }));
  const [selectedOptions, setSelectedOptions] = useState([]);
  // const handleChangeSub = (selectedOptions) => {
  //   setSelectedOptions(selectedOptions);
  //   // Example of setting a value based on the selected options
  //   const selectedValues = selectedOptions.map((option) => option.value); // Extracting selected values
  //   setFormData({
  //     ...formData,
  //     subjects: selectedValues,
  //   });
  //   // console.log("Selected Values:", selectedValues);
  // };

  const [errorMessage, setErrorMessage] = useState("");

  const handleChangeSub = (selectedOptions) => {
    // Extracting selected values
    const selectedValues = selectedOptions.map((option) => option.value);

    // Extracting subject IDs from mySubjects
    const existingSubjectIds = mySubjects.map((subject) => subject.subject_id);

    // Filter out duplicates
    const filteredSelectedValues = selectedValues.filter(
      (value) => !existingSubjectIds.includes(value)
    );

    if (filteredSelectedValues.length !== selectedValues.length) {
      // If some subjects were removed, show an error message
      setErrorMessage(
        "Some subjects are already selected and have been removed."
      );
    } else {
      // Clear the error message if there are no duplicates
      setErrorMessage("");
    }

    // Update the selected options
    setSelectedOptions(
      selectedOptions.filter(
        (option) => !existingSubjectIds.includes(option.value)
      )
    );

    // Update the form data
    setFormData({
      ...formData,
      subjects: filteredSelectedValues,
    });
  };
  return (
    <>
      <div className="flex items-center xl:p-5 sm:p-0 justify-center">
        <div className="w-full border rounded-3xl shadow-xl p-5 px-10">
          <div className="mt-3  flex  items-center">
            <div>
              {" "}
              <p className="text-2xl font-semibold ">Settings</p>
            </div>
          </div>

          {/* tableee----------- */}
          <div className="flex flex-wrap md:justify-start justify-center  space-x-5  border-b-2 text-base font-medium my-5">
            {/* <NavLink
              to=".././profileSettingTech"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              Profile Settings
            </NavLink> */}

            <NavLink
            //   to=".././subjectTech"
              className="border-blue-500 border-b-2"
            >
              Subjects & WP
            </NavLink>
{/* 
            <NavLink
              to=".././otherDetail"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              Other Details
            </NavLink> */}
{/* 
            <NavLink
              to=".././security"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              Privacy & Security
            </NavLink> */}

            {/* <NavLink
              to=".././techerNotiSetting"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              Notification
            </NavLink> */}
          </div>
          {/* end---------- */}

          <div className="flex justify-between">
            <div className="mt-5">
              <p className="text-[22px] font-semibold ">
                Subjects & Work Preferences
              </p>
            </div>

            <div className="flex space-x-5 mt-5 cursor-pointer ">
              <p
                className="text-[14px] flex gap-x-2 text-[#5963F5] border border-[#5963F5] px-6 py-2 rounded-lg"
                onClick={() => {
                  setFormData({
                    teacher_id: teacher_id,
                    class_id: "",
                    subjects: [],
                    type_id: typeId,
                    online_charges: "",
                    home_charges: "",
                  });

                  setShowModal(true);
                }}
              >
                <FaPlus className="mt-1" />
                Add
              </p>
            </div>
          </div>

          <p className="mt-5 text-lg font-semibold">Main Subject</p>
          {loading ? (
            <div>
              <div colSpan="9" className="py-5 px-1 text-center">
                <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-200 bg-opacity-5 backdrop-blur-sm z-50">
                  <div className="w-12 h-12 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-[#2DD0A5]"></div>
                  <p className="mt-4 text-lg font-semibold text-[#2dd0a5]">
                    Edolution...
                  </p>
                </div>
              </div>
            </div>
          ) : Object.entries(groupedSubjects).length === 0 ? (
            <div>
              <div colSpan="9" className="py-5 px-1 text-center">
                <p className="text-gray-500">
                  No Specific Subjects are Selected
                </p>
              </div>
            </div>
          ) : (
            <div className="mt-5 w-full grid md:grid-cols-3  gap-4">
              {Object.entries(groupedSubjects).map(([id, subjects]) => (
                <div
                  key={id}
                  className="flex flex-col border border-[#B5B7C0] rounded-2xl p-5 w-full"
                >
                  <div>
                    <p className="text-[16px] font-semibold">
                      {subjects[0].class_name}
                    </p>
                    <div className="mb-4 mt-3">
                      <p className="text-[14px] font-semibold">
                        {subjects
                          .map((subject) => subject.subject_name)
                          .join(" | ")}
                      </p>
                    </div>
                    {/* {subjects[0].home_charges && (
                      <p className="text-[12px] text-[#B5B7C0] font-semibold">
                        Home Charges: Rs.{subjects[0].home_charges}
                      </p>
                    )}
                    {subjects[0].online_charges && (
                      <p className="text-[12px] text-[#B5B7C0] font-semibold">
                        Online Charges: Rs.{subjects[0].online_charges}
                      </p>
                    )} */}
                    {(typeId === 1 || typeId === 3) &&
                      subjects[0].home_charges && (
                        <p className="text-[12px] text-[#B5B7C0] font-semibold">
                          Home Charges: Rs.{subjects[0].home_charges}
                        </p>
                      )}
                    {(typeId === 2 || typeId === 3) &&
                      subjects[0].online_charges && (
                        <p className="text-[12px] text-[#B5B7C0] font-semibold">
                          Online Charges: Rs.{subjects[0].online_charges}
                        </p>
                      )}
                  </div>

                  <div className="flex text-blue-500 gap-x-3 text-[20px] items-center cursor-pointer mt-4">
                    <div
                      onClick={() =>
                        handleFunctionEdit(
                   
                          subjects[0].id,
                          subjects[0].subject_name,
                          subjects[0].class_name,
                          subjects[0].home_charges,
                          subjects[0].online_charges
                        )
                      }
                    >
                      <TbBallpen />
                    </div>
                    <button onClick={() => handleDeleteSubject(subjects[0].id)}>
                      <FaRegTrashAlt />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* modal */}
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative mx-auto max-w-lg w-full">
              {/* {/content/} */}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none overflow-y-auto shiftmodel p-5">
                {/* {/header/} */}
                <div className="flex items-start justify-center  border-solid border-blueGray-200 rounded-t">
                  <button
                    className=" ml-auto  float-right text-2xl"
                    onClick={() => setShowModal(false)}
                  >
                    <span> × </span>
                  </button>
                </div>

                <div className="flex justify-center">
                  <h1 className="text-2xl font-medium">Add Subject</h1>
                </div>

                <form onSubmit={handleSubmit} className="m-auto w-full">
                  <div>
                    <p className="text-lg mt-2 font-medium">Class</p>
                  </div>
                  <div>
                    <select
                      className="text-[#B5B7C0] text-sm dropdown-select w-full mt-2 rounded-lg outline-none bg-[#F9FBFF] px-5 py-2"
                      onChange={handleChange}
                      name="class_id"
                    >
                      <option value={JSON.stringify({})}>Select Class</option>
                      {classes.map((classItem, index) => (
                        <option key={index} value={classItem.id}>
                          {classItem.class_name}
                        </option>
                      ))}
                    </select>
                    {errors.class_id && (
                      <div className="text-red-500 text-start text-sm">
                        {`*Select class!`}
                      </div>
                    )}
                  </div>
                  <div>
                    <p className="text-lg mt-2 font-medium">Subject</p>
                  </div>
                  <div>
                    {/* new MultiSelection---------------------------------------------- */}

                    <Select
                      className="text-[#B5B7C0] text-sm dropdown-select w-full mt-2 rounded-lg outline-none bg-[#F9FBFF]"
                      options={options}
                      isMulti={true}
                      value={selectedOptions}
                      onChange={handleChangeSub}
                      closeMenuOnSelect={false}
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          color: "#000",
                        }),
                        multiValue: (provided) => ({
                          ...provided,
                          backgroundColor: "#1E90FF",
                          borderRadius: "0.25rem",
                        }),
                        multiValueLabel: (provided) => ({
                          ...provided,
                          color: "#fff",
                        }),
                        multiValueRemove: (provided) => ({
                          ...provided,
                          color: "#fff", // Remove icon color
                          ":hover": {
                            backgroundColor: "#1E90FF", // Hover color for remove icon
                            color: "#fff", // Hover color for remove icon
                          },
                        }),
                      }}
                    />

                    {/* end multi selection====================================== */}

                    {errors.subject_id && (
                      <div className="text-red-500 text-start text-sm">
                        {`*Select Subject!`}
                        {errorMessage}
                      </div>
                    )}
                  </div>
                  <div>
                    <p className="text-lg mt-2 font-medium">
                      Are you willing to teach the main subject
                    </p>
                    {errors.class_id && (
                      <div className="text-red-500 text-start text-sm">
                        {`*Select Slot!`}
                      </div>
                    )}
                  </div>
                  {/* <div className="flex justify-between mt-4 mx-3">
                    <div className="flex items-center">
                      <input
                        id="radio1"
                        type="radio"
                        name="type_id"
                        value="online"
                        checked={selectedOption === "online"}
                        onChange={() => {
                          setSelectedOption("online");
                          setFormData({ ...formData, type_id: "2" });
                        }}
                        className="form-radio h-4 w-4 text-blue-600"
                      />
                      <label
                        htmlFor="radio1"
                        className="ml-2 text-gray-700 text-base font-medium"
                      >
                        Online Tutoring
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="radio2"
                        type="radio"
                        name="type_id"
                        value="home"
                        checked={selectedOption === "home"}
                        onChange={() => {
                          setSelectedOption("home");
                          setFormData({ ...formData, type_id: "1" });
                        }}
                        className="form-radio h-4 w-4 text-blue-600"
                      />
                      <label
                        htmlFor="radio2"
                        className="ml-2 text-gray-700 text-base font-medium"
                      >
                        At Home
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="radio3"
                        type="radio"
                        name="type_id"
                        value="both"
                        checked={selectedOption === "both"}
                        onChange={() => {
                          setSelectedOption("both");
                          setFormData({ ...formData, type_id: "3" });
                        }}
                        className="form-radio h-4 w-4 text-blue-600"
                      />
                      <label
                        htmlFor="radio3"
                        className="ml-2 text-gray-700 text-base font-medium"
                      >
                        Both Tutoring
                      </label>
                    </div>
                  </div> */}

                  {selectedOption === "online" && (
                    <div>
                      <p className="text-lg mt-2 font-medium">
                        Charges per hour (online)- in PKR
                      </p>
                      <input
                        type="text"
                        name="online_charges"
                        value={formData.online_charges}
                        onChange={handleChange}
                        className="text-[#B5B7C0] text-sm dropdown-select w-full mt-2 rounded-lg outline-none bg-[#F9FBFF] px-5 py-2"
                        placeholder="eg. Rs.1500 (per hour)"
                      />
                    </div>
                  )}

                  {selectedOption === "home" && (
                    <div>
                      <p className="text-lg mt-2 font-medium">
                        Charges per hour (home)- in PKR
                      </p>
                      <input
                        type="text"
                        name="home_charges"
                        value={formData.home_charges}
                        onChange={handleChange}
                        className="text-[#B5B7C0] text-sm dropdown-select w-full mt-2 rounded-lg outline-none bg-[#F9FBFF] px-5 py-2"
                        placeholder="eg. Rs.1500 (per hour)"
                      />
                    </div>
                  )}

                  {selectedOption === "both" && (
                    <>
                      <div>
                        <p className="text-lg mt-2 font-medium">
                          Charges per hour (online)- in PKR
                        </p>
                        <input
                          type="text"
                          name="online_charges"
                          value={formData.online_charges}
                          onChange={handleChange}
                          className="text-[#B5B7C0] text-sm dropdown-select w-full mt-2 rounded-lg outline-none bg-[#F9FBFF] px-5 py-2"
                          placeholder="eg. Rs.1500 (per hour)"
                        />
                      </div>
                      <div>
                        <p className="text-lg mt-2 font-medium">
                          Charges per hour (home)- in PKR
                        </p>
                        <input
                          type="text"
                          name="home_charges"
                          value={formData.home_charges}
                          onChange={handleChange}
                          className="text-[#B5B7C0] text-sm dropdown-select w-full mt-2 rounded-lg outline-none bg-[#F9FBFF] px-5 py-2"
                          placeholder="eg. Rs.1500 (per hour)"
                        />
                      </div>
                    </>
                  )}

                  <div className="flex justify-center my-5 mt-8 cursor-pointer">
                    <button
                      onClick={handleSubmit} // Ensure this triggers your submit function
                      className={`bg-blue-500 w-fit hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg ml-2 focus:outline-none focus:shadow-outline ${
                        isLoading ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                      disabled={isLoading} // Disable button while loading
                    >
                      {isLoading ? (
                        <svg
                          className="animate-spin h-5 w-5 mr-3 inline"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {/* modal-end */}

      {/* modal */}
      {showModalEdit ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative mx-auto max-w-lg w-full">
              {/* {/content/} */}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none overflow-y-auto shiftmodel p-5">
                {/* {/header/} */}
                <div className="flex items-start justify-center  border-solid border-blueGray-200 rounded-t">
                  <button
                    className=" ml-auto  float-right text-2xl"
                    onClick={() => {
                      setFormData(initialFormData);
                      setSelectedOptions([]);
                      setShowModalEdit(false);
                    }}
                  >
                    <span> × </span>
                  </button>
                </div>

                <div className="flex justify-center">
                  <h1 className="text-2xl font-medium">Edit Subject</h1>
                </div>

                <form onSubmit={handleSubmitEdit} className="m-auto w-full">
                  <div>
                    <p className="text-lg mt-2 font-medium">Class</p>
                  </div>
                  <div>
                    <select
                      className="text-[#B5B7C0] text-sm dropdown-select w-full mt-2 rounded-lg outline-none bg-[#F9FBFF] px-5 py-2"
                      onChange={handleChange}
                      name="class_id"
                      // value={edit.class_id}
                    >
                      <option value={edit.class_id}>{editclass}</option>
                      {classes.map((classItem, index) => (
                        <option
                          key={index}
                          value={classItem.id}
                          selected={edit.class_id === classItem.id}
                        >
                          {classItem.class_name}
                        </option>
                      ))}
                    </select>
                    {errors.class_id && (
                      <div className="text-red-500 text-start text-sm">
                        {`*Select class!`}
                      </div>
                    )}
                  </div>
                  <div>
                    <p className="text-lg mt-2 font-medium">Subject</p>
                  </div>

                  <Select
                    className="text-[#B5B7C0] text-sm dropdown-select w-full mt-2 rounded-lg outline-none bg-[#F9FBFF] "
                    options={options}
                    isMulti={true}
                    value={selectedOptions}
                    onChange={handleChangeSub}
                    closeMenuOnSelect={false}
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        color: "#000",
                      }),
                      multiValue: (provided) => ({
                        ...provided,
                        backgroundColor: "#1E90FF",
                        borderRadius: "0.25rem",
                      }),
                      multiValueLabel: (provided) => ({
                        ...provided,
                        color: "#fff",
                      }),
                      multiValueRemove: (provided) => ({
                        ...provided,
                        color: "#fff", // Remove icon color
                        ":hover": {
                          backgroundColor: "#1E90FF", // Hover color for remove icon
                          color: "#fff", // Hover color for remove icon
                        },
                      }),
                    }}
                  />

                  <div>
                    <p className="text-lg mt-2 font-medium">
                      Are you willing to teach the main subject
                    </p>
                    {errors.class_id && (
                      <div className="text-red-500 text-start text-sm">
                        {`*Select Slot!`}
                      </div>
                    )}
                  </div>
                  {/* <div className="flex justify-between mt-4 mx-3">
                    <div className="flex items-center">
                      <input
                        id="radio1"
                        type="radio"
                        name="type_id"
                        value="online"
                        checked={formData.type_id === 2}
                        onChange={() => {
                          setSelectedOption("online");
                          setFormData({ ...formData, type_id: 2 });
                        }}
                        className="form-radio h-4 w-4 text-blue-600"
                      />
                      <label
                        htmlFor="radio1"
                        className="ml-2 text-gray-700 text-base font-medium"
                      >
                        Online Tuitoring
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="radio2"
                        type="radio"
                        name="type_id"
                        value="home"
                        checked={formData.type_id === 1}
                        onChange={() => {
                          setSelectedOption("home");
                          setFormData({ ...formData, type_id: 1 });
                        }}
                        className="form-radio h-4 w-4 text-blue-600"
                      />
                      <label
                        htmlFor="radio2"
                        className="ml-2 text-gray-700 text-base font-medium"
                      >
                        At Home
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="radio3"
                        type="radio"
                        name="type_id"
                        value="both"
                        checked={formData.type_id === 3}
                        onChange={() => {
                          setSelectedOption("both");
                          setFormData({ ...formData, type_id: 3 });
                        }}
                        className="form-radio h-4 w-4 text-blue-600"
                      />
                      <label
                        htmlFor="radio3"
                        className="ml-2 text-gray-700 text-base font-medium"
                      >
                        Both Tuitoring
                      </label>
                    </div>
                  </div> */}
                  {formData.type_id === 2 && (
                    <div>
                      <p className="text-lg mt-2 font-medium">
                        Charges Range (Online)
                      </p>
                      <input
                        type="text"
                        name="online_charges"
                        value={editCharge}
                        onChange={(e) => {
                          handleChange(e);
                          setEditCharge(e.target.value);
                        }}
                        className="text-[#B5B7C0] text-sm dropdown-select w-full mt-2 rounded-lg outline-none bg-[#F9FBFF] px-5 py-2"
                        placeholder="eg. Rs.1500 (per hour)"
                      />
                    </div>
                  )}

                  {formData.type_id === 1 && (
                    <div>
                      <p className="text-lg mt-2 font-medium">
                        Charges Range (At Home)
                      </p>
                      <input
                        type="text"
                        name="home_charges"
                        value={edithome}
                        onChange={(e) => {
                          handleChange(e);
                          setEditHome(e.target.value);
                        }}
                        className="text-[#B5B7C0] text-sm dropdown-select w-full mt-2 rounded-lg outline-none bg-[#F9FBFF] px-5 py-2"
                        placeholder="eg. Rs.1500 (per hour)"
                      />
                    </div>
                  )}

                  {formData.type_id === 3 && (
                    <>
                      <div>
                        <p className="text-lg mt-2 font-medium">
                          Charges Range (Online)
                        </p>
                        <input
                          type="text"
                          name="online_charges"
                          value={editCharge}
                          onChange={(e) => {
                            handleChange(e);
                            setEditCharge(e.target.value);
                          }}
                          className="text-[#B5B7C0] text-sm dropdown-select w-full mt-2 rounded-lg outline-none bg-[#F9FBFF] px-5 py-2"
                          placeholder="eg. Rs.1500 (per hour)"
                        />
                      </div>
                      <div>
                        <p className="text-lg mt-2 font-medium">
                          Charges Range (At Home)
                        </p>
                        <input
                          type="text"
                          name="home_charges"
                          value={edithome}
                          onChange={(e) => {
                            handleChange(e);
                            setEditHome(e.target.value);
                          }}
                          className="text-[#B5B7C0] text-sm dropdown-select w-full mt-2 rounded-lg outline-none bg-[#F9FBFF] px-5 py-2"
                          placeholder="eg. Rs.1500 (per hour)"
                        />
                      </div>
                    </>
                  )}

                  <div className="flex justify-center my-5 mt-8 cursor-pointer">
                    <button
                      className={`bg-blue-500 w-fit hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg ml-2 focus:outline-none focus:shadow-outline ${
                        isLoading2 ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                      disabled={isLoading2}
                    >
                      {isLoading2 ? (
                        <svg
                          className="animate-spin h-5 w-5 text-white inline-block"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {/* modal-end */}
    </>
  );
};

export default AdminAddSubject;
