import React, { useState } from "react";

const countries = [
  { code: "https://flagcdn.com/w320/pk.png", name: "PAK" },
  { code: "https://flagcdn.com/w320/ae.png", name: "UAE" },
  { code: "https://flagcdn.com/w320/sa.png", name: "KSA" },
];

const CountrySelector = () => {
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleSelect = (country) => {
    setSelectedCountry(country);
    setIsDropdownOpen(false); // Close dropdown after selection
  };

  return (
    <div className="relative w-40">
      {/* Selected Country */}
      <div
        className="flex w-28 items-center justify-between bg-white  rounded-lg px-3 py-2 cursor-pointer"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <div className="flex items-center">
          <img
            src={selectedCountry.code}
            alt={selectedCountry.name}
            className="w-5 h-4 mr-2"
          />
          {selectedCountry.name}
        </div>
        <span>▼</span>
      </div>

      {/* Dropdown */}
      {isDropdownOpen && (
        <div
          className="absolute z-10 w-full bg-white border border-gray-300 rounded-lg mt-1"
        >
          {countries.map((country) => (
            <div
              key={country.name}
              className="flex items-center px-3 py-2 cursor-pointer hover:bg-gray-100"
              onClick={() => handleSelect(country)}
            >
              <img
                src={country.code}
                alt={country.name}
                className="w-5 h-4 mr-2"
              />
              {country.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CountrySelector;
