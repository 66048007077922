import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaFile, FaLaptop } from "react-icons/fa";
import { LiaStarSolid } from "react-icons/lia";
import { BiArrowBack } from "react-icons/bi";
import { FaRegTrashAlt } from "react-icons/fa";
import { TbBallpen } from "react-icons/tb";
import Background from "../../../assets/background.png";
import axios from "axios";
import apiKey from "../../../config/apiKey";
import { getHitID } from "../../../config/HitIDStorage";
import { toast } from "react-toastify";
import { IoChevronDownOutline, IoChevronUp } from "react-icons/io5";
import { ImUpload } from "react-icons/im";
function TeacherMessage() {
  const ID = getHitID();

  const [errors, setErrors] = useState({});
  const admin = JSON.parse(localStorage.getItem("edo-admin"));
  const [teacherDetails, setTeacherDetails] = useState(null);
  const [bySubjectsData, setBySubjectsData] = useState({});
  const [bySkillData, setBySkillData] = useState([]);
  const [byTimeSlotData, setByTimeSlotData] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [profileImage, setProfileImage] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [editing, setEditing] = useState({
    about: false,
    education: false,
    job: false,
  });

  const [formData, setFormData] = useState({
    bio: "",
    degree: "",
    school: "",
    graduation_year: "",
    title: "",
    company: "",
    start_month: "",
    start_year: "",
    end_month: "",
    end_year: "",
    description: "",
  });

  useEffect(() => {
    setLoading(true); // Start loading before data fetch

    axios
      .get(`${apiKey}/api/edolutions/admin/teacherdetails/${ID}`, {
        headers: {
          Authorization: `Bearer ${admin?.token}`,
        },
      })
      .then((response) => {
        // Add this line to check the data structure

        setTeacherDetails(response.data.teacherDetails);
        setBySubjectsData(response.data.bySubjectsData);

        setBySkillData(response.data.skillsData);
        setByTimeSlotData(response.data.timeSlotData);
        
        setProfileImage(teacherDetails.photo);

        setFormData({
          bio: response.data.teacherDetails.bio,
          degree: response.data.teacherDetails.degree,
          school: response.data.teacherDetails.school,
          graduation_year: response.data.teacherDetails.graduation_year,
          title: response.data.teacherDetails.title,
          company: response.data.teacherDetails.company,
          start_month: response.data.teacherDetails.start_month,
          start_year: response.data.teacherDetails.start_year,
          end_month: response.data.teacherDetails.end_month,
          end_year: response.data.teacherDetails.end_year,
          description: response.data.teacherDetails.description,
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false); // Stop loading after data fetch
      });
  }, [ID, admin?.token]);


  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const ID = getHitID(); // Get the ID dynamically

    // Validation: Check if file exists
    if (!file) {
      toast.error("No file selected!", {
        position: "bottom-right",
        autoClose: 5000,
        theme: "colored",
      });
      return;
    }

    // Validation: Allowed file types
    const validExtensions = ["image/png", "image/jpeg"];
    if (!validExtensions.includes(file.type)) {
      toast.error("Only PNG or JPG files are allowed.", {
        position: "bottom-right",
        autoClose: 5000,
        theme: "colored",
      });
      e.target.value = ""; // Reset the file input
      return;
    }

    // Validation: File size (1MB = 1 * 1024 * 1024 bytes)
    const maxSize = 1 * 1024 * 1024;
    if (file.size > maxSize) {
      toast.error("File size must be less than 1MB.", {
        position: "bottom-right",
        autoClose: 5000,
        theme: "colored",
      });
      e.target.value = ""; // Reset the file input
      return;
    }

    // Prepare the file for upload
    const formData = new FormData();
    formData.append("photo", file);
    formData.append("user_id", ID); // Add the user ID to the request

    // Upload the image
    axios
      .post(`${apiKey}/api/edolutions/teacher-image-upload`, formData, {

      })
      .then((res) => {
        toast.success(res.data.message, {
          position: "bottom-right",
          autoClose: 5000,
          theme: "colored",
          style: {
            backgroundColor: "#4FD1C5", // Customize background color
            color: "#fff", // Customize text color
          },
        });

        // Fetch the updated profile image
        const formData1 = new FormData();
        formData1.append("user_id", ID);

        axios
        .post(`${apiKey}/api/edolutions/get-image`, formData1) // Change from GET to POST
        .then((res) => {
          if (res.data[0]) {
            setProfileImage(res.data[0].photo_url);
          }
        })
        .catch((err) => {
          console.error("Error fetching profile image:", err);
          toast.error("Failed to fetch updated profile image.", {
            position: "bottom-right",
            autoClose: 5000,
            theme: "colored",
          });
        });
      })
      .catch((err) => {
        console.error("Error uploading image:", err);
        toast.error(err.response?.data?.message || "Image upload failed.", {
          position: "bottom-right",
          autoClose: 5000,
          theme: "colored",
        });
      });
  };

  const handleEditClick = (section) => {
    setEditing({ ...editing, [section]: !editing[section] });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = () => {
    axios
      .post(
        `${apiKey}/api/edolutions/admin/updateTeacherDetails/${ID}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${admin?.token}`,
          },
        }
      )
      .then((response) => {
        setTeacherDetails(response.data.teacherDetails);
        setEditing({
          about: false,
          education: false,
          job: false,
        });
        setErrors({});
        toast.success("Profile Details Update  Successfully", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            backgroundColor: "#4FD1C5", // Customize background color
            color: "#fff", // Customize text color
          },
        });
      })
      .catch((error) => {
        console.error("Error updating data:", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          setEditing({
            about: true,
            education: true,
            job: true,
          });
          setErrors(error.response.data.errors);
          toast.error("Error updating profile. Please check the fields.", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error("An unexpected error occurred. Please try again later.", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };

  const formatTime = (time) => {
    const [hour, minute] = time.split(":");
    const suffix = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour % 12 || 12; // Convert 0 to 12 for 12 AM
    return `${formattedHour}:${minute} ${suffix}`;
  };

  const navigate = useNavigate();
  const [expandedClasses, setExpandedClasses] = useState({});

  const toggleClass = (className) => {
    setExpandedClasses((prev) => ({
      ...prev,
      [className]: !prev[className],
    }));
  };

  // time slot work
  const [visibleCount, setVisibleCount] = useState(4);
  const [showAll, setShowAll] = useState(false);

  // Function to toggle showing more or less time slots
  const handleToggleView = () => {
    if (showAll) {
      setVisibleCount(4); // Reset to initial visible count
    } else {
      setVisibleCount(byTimeSlotData.length); // Show all time slots
    }
    setShowAll((prevState) => !prevState); // Toggle between showing more and less
  };

  return (
    <div className="m-3 flex flex-col break-words w-full mb-4 shadow-lg bg-white rounded-lg border-0">
      <div className="flex items-center xl:p-5 sm:p-0 justify-center">
        {loading ? (
          <div>
            <div colSpan="9" className="py-5 px-1 text-center">
              <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-200 bg-opacity-5 backdrop-blur-sm z-50">
                <div className="w-12 h-12 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-[#2DD0A5]"></div>
                <p className="mt-4 text-lg font-semibold text-[#2dd0a5]">
                  Edolution...
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full xl:p-5 sm:p-0">
            <div className="p-3">
              <div
                className="flex gap-2 items-center cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <BiArrowBack className="h-5 w-5" />
                <p className="text-[14px]">Back</p>
              </div>
              <div className="xl:mx-10 mx-0">
                <div
                  className="bg-cover p-4"
                  style={{ backgroundImage: `url(${Background})` }}
                >
                  <div className="flex flex-wrap items-center justify-between gap-7 my-7">
                    <div className="flex flex-col items-center justify-center text-center">
                      <div>
                        {teacherDetails && (
                          <div className="flex flex-col  items-center justify-center">
                            <div className="">
                              <img
                                className="rounded-full border w-40 h-40"
                                src={
                                  profileImage
                                    ? profileImage
                                    : teacherDetails.photo
                                      ? `${apiKey}/userProfile/${teacherDetails.photo}`
                                      : `${apiKey}/userProfile/87654321.png`
                                }
                                alt="Profile"
                              />
                            </div>
                            <div className="relative inline-block">
                              <input
                                onChange={handleImageUpload}
                                type="file"
                                id="uploadBtn"
                                className=""
                                accept="image/png, image/jpeg" // Restricts file types in the file picker
                              />
                              <label
                                htmlFor="uploadBtn"
                                className="flex items-center cursor-pointer"
                                onMouseEnter={() => setShowTooltip(true)}
                                onMouseLeave={() => setShowTooltip(false)}
                              >
                                <ImUpload className="w-6 h-6 ml-32 -mt-6" />
                              </label>
                              {showTooltip && (
                                <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 p-2 w-48 font-medium text-black bg-white text-sm rounded shadow-lg z-10">
                                  Only PNG or JPEG Image under 1MB is allowed.
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      <div>
                        {teacherDetails && (
                          <p className="font-semibold capitalize text-xl">
                            {teacherDetails.user_name}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-3">
              <div className="md:px-4 md:py-4 col-span-1 sm:col-span-2 md:col-span-2 lg:col-span-2 xl:col-span-2 space-y-5">
                <div className="border rounded-3xl shadow-xl p-5">
                  <p className="text-2xl font-semibold mb-3">About</p>
                  {editing.about ? (
                    <textarea
                      name="bio"
                      value={formData.bio}
                      onChange={handleChange}
                      className="w-full border p-2 rounded"
                      placeholder="Bio"
                    />
                  ) : (
                    <p className="text-sm font-light">{teacherDetails?.bio}</p>
                  )}
                  {errors.bio && <p className="text-red-500">{errors.bio}</p>}
                  <button
                    onClick={() => handleEditClick("about")}
                    className="text-blue-500 mt-5"
                  >
                    {editing.about ? <FaRegTrashAlt /> : <TbBallpen />}
                  </button>
                </div>

                <div className="border rounded-3xl shadow-xl p-5">
                  <p className="text-2xl font-semibold mb-3">Education</p>
                  {editing.education ? (
                    <div>
                      <input
                        name="degree"
                        value={formData.degree}
                        onChange={handleChange}
                        className="w-full border p-2 rounded"
                        placeholder="Degree"
                      />
                      {errors.degree && (
                        <p className="text-red-500">{errors.degree}</p>
                      )}
                      <input
                        name="school"
                        value={formData.school}
                        onChange={handleChange}
                        className="w-full border p-2 rounded"
                        placeholder="School"
                      />
                      {errors.school && (
                        <p className="text-red-500">{errors.school}</p>
                      )}
                      <input
                        name="graduation_year"
                        value={formData.graduation_year}
                        onChange={handleChange}
                        className="w-full border p-2 rounded"
                        placeholder="Graduation Year"
                      />
                      {errors.graduation_year && (
                        <p className="text-red-500">{errors.graduation_year}</p>
                      )}
                    </div>
                  ) : (
                    <div>
                      <p className="text-sm font-semibold">
                        {teacherDetails?.degree}
                      </p>
                      <p className="text-sm font-light">
                        {teacherDetails?.school}
                      </p>
                      <p className="text-sm font-light mb-3">
                        Graduation Year: {teacherDetails?.graduation_year}
                      </p>
                    </div>
                  )}
                  <button
                    onClick={() => handleEditClick("education")}
                    className="text-blue-500 mt-5"
                  >
                    {editing.education ? <FaRegTrashAlt /> : <TbBallpen />}
                  </button>
                </div>

                <div className="border rounded-3xl shadow-xl p-5">
                  <p className="text-2xl font-semibold mb-3">Job</p>
                  {editing.job ? (
                    <div>
                      <input
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                        className="w-full border p-2 rounded"
                        placeholder="Job Title"
                      />
                      {errors.title && (
                        <p className="text-red-500">{errors.title}</p>
                      )}
                      <input
                        name="company"
                        value={formData.company}
                        onChange={handleChange}
                        className="w-full border p-2 rounded"
                        placeholder="Company"
                      />
                      {errors.company && (
                        <p className="text-red-500">{errors.company}</p>
                      )}
                      <input
                        name="start_month"
                        value={formData.start_month}
                        onChange={handleChange}
                        className="w-full border p-2 rounded"
                        placeholder="Start Month"
                      />
                      {errors.start_month && (
                        <p className="text-red-500">{errors.start_month}</p>
                      )}
                      <input
                        name="start_year"
                        value={formData.start_year}
                        onChange={handleChange}
                        className="w-full border p-2 rounded"
                        placeholder="Start Year"
                      />
                      {errors.start_year && (
                        <p className="text-red-500">{errors.start_year}</p>
                      )}
                      <input
                        name="end_month"
                        value={formData.end_month}
                        onChange={handleChange}
                        className="w-full border p-2 rounded"
                        placeholder="End Month"
                      />
                      {errors.end_month && (
                        <p className="text-red-500">{errors.end_month}</p>
                      )}
                      <input
                        name="end_year"
                        value={formData.end_year}
                        onChange={handleChange}
                        className="w-full border p-2 rounded"
                        placeholder="End Year"
                      />
                      {errors.end_year && (
                        <p className="text-red-500">{errors.end_year}</p>
                      )}
                      <textarea
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        className="w-full border p-2 rounded"
                        placeholder="Job Description"
                      />
                      {errors.description && (
                        <p className="text-red-500">{errors.description}</p>
                      )}
                    </div>
                  ) : (
                    <div>
                      <p className="text-sm font-semibold">
                        {teacherDetails?.title}
                      </p>
                      <p className="text-sm font-light">
                        {teacherDetails?.company}
                      </p>
                      <p className="text-sm font-light">
                        Start Date: {teacherDetails?.start_month},{" "}
                        {teacherDetails?.start_year}
                      </p>
                      <p className="text-sm font-light mb-3">
                        End Date: {teacherDetails?.end_month},{" "}
                        {teacherDetails?.end_year}
                      </p>
                      <p className="text-sm font-light mb-3">
                        Job Description: {teacherDetails?.description}
                      </p>
                    </div>
                  )}
                  <button
                    onClick={() => handleEditClick("job")}
                    className="text-blue-500 mt-5"
                  >
                    {editing.job ? <FaRegTrashAlt /> : <TbBallpen />}
                  </button>
                </div>
              </div>
              <div className="col-span-1 sm:col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1 md:px-4 md:py-4 overflow-x-auto space-y-5 mt-5 md:mt-0">
                <div className="border rounded-3xl shadow-xl p-5">
                  <p className="text-2xl font-semibold mb-2">Time</p>
                  {byTimeSlotData && byTimeSlotData.length > 0 ? (
                    <div>
                      {byTimeSlotData.slice(0, visibleCount).map((timeSlot) => (
                        <div key={timeSlot.id}>
                          <p className="text-sm font-semibold capitalize">
                            {timeSlot.day_name}:
                          </p>
                          <p className="text-sm font-normal">
                            Start Time: {formatTime(timeSlot.start_time)} End
                            Time: {formatTime(timeSlot.end_time)}
                          </p>
                        </div>
                      ))}
                      {byTimeSlotData.length > 4 && (
                        <button
                          className="mt-3 text-blue-500 font-semibold"
                          onClick={handleToggleView}
                        >
                          {showAll ? "Show Less" : "View More"}
                        </button>
                      )}
                    </div>
                  ) : (
                    <p>No time slots available</p>
                  )}
                </div>

                <div className="border rounded-3xl shadow-xl p-5">
                  <p className="text-2xl font-semibold mb-2">Skills</p>
                  {bySkillData && bySkillData.length > 0 ? (
                    bySkillData.map((skill) => (
                      <p className="text-sm font-semibold" key={skill.id}>
                        {skill.skill_name}
                      </p>
                    ))
                  ) : (
                    <p>No skills available</p>
                  )}
                </div>

                <div className="border rounded-3xl shadow-xl p-5">
                  <p className="text-2xl font-semibold mb-3">
                    Profile Overview
                  </p>

                  <p className="flex text-yellow-400 items-center">
                    <LiaStarSolid />
                    <LiaStarSolid />
                    <LiaStarSolid />
                    <span className="text-black">5.0</span>
                  </p>
                  <div>
                    <div className="flex items-center text-base font-semibold space-x-1 mb-2">
                      <p className="text-lg">
                        <FaLaptop />
                      </p>
                      <p>Home Tuition Location</p>
                    </div>
                    <div>
                      {teacherDetails &&
                        teacherDetails.location ? (
                        <p className="text-sm font-semibold">
                          {teacherDetails.location}
                        </p>
                      ) : (
                        <p>No location available</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="border rounded-3xl shadow-xl p-5 bg-white">
                  <p className="text-2xl font-semibold mb-5 text-gray-800 flex items-start justify-between">Subjects <Link to={'/admins/editsubject'} className="text-blue-400"><TbBallpen /></Link > </p>

                  <div className="flex items-center text-lg font-semibold mb-4 text-black">
                    <p className="mr-2">
                      <FaFile />
                    </p>
                    <p>Main Classes & Subjects</p>
                  </div>
                   {console.log(bySubjectsData)}   
                  {bySubjectsData && Object.keys(bySubjectsData).length > 0 ? (
                    Object.keys(bySubjectsData).map((className) => (
                      <div key={className} className="mb-4 border-b pb-2">
                        <div
                          className="cursor-pointer flex items-center justify-between text-md font-medium text-gray-600 hover:text-blue-700"
                          onClick={() => toggleClass(className)}
                        >
                          <p>{className || "General"}</p>
                          <span>
                            {expandedClasses[className] ? <IoChevronUp /> : <IoChevronDownOutline />}
                          </span>
                        </div>

                        {expandedClasses[className] && (
                          <ul className="pl-5 mt-2 space-y-2">
                            {bySubjectsData[className] &&
                              bySubjectsData[className].map((subject, index) => (
                                <li key={index} className="text-sm text-gray-700">
                                  <p className="font-semibold text-gray-800">{subject.subject}</p>
                                  <div className="flex items-center space-x-4 text-xs text-gray-600">
                                    <span>Home Charges: {subject.home_charges ? `Rs${subject.home_charges}` : "N/A"}</span>
                                    <span>Online Charges: {subject.online_charges ? `Rs${subject.online_charges}` : "N/A"}</span>
                                  </div>
                                </li>
                              ))}
                          </ul>
                        )}
                      </div>
                    ))
                  ) : (
                    <p className="text-gray-500">No subjects available</p>
                  )}
                </div>

              </div>
            </div>
            {editing.about || editing.education || editing.job ? (
              <button
                onClick={handleSave}
                className="bg-blue-500 text-white p-2 rounded mt-4"
              >
                Save Changes
              </button>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}

export default TeacherMessage;
